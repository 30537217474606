var m = require("mithril")
var Info = require("./Info");
var BlockList = require("./BlockList");
var BottomLine = require("./BottomLine");

var FooterProducts = require("../../models/FooterProducts");
var FooterResources = require("../../models/FooterResources");
var FooterProject = require("../../models/FooterProject");

module.exports = {
  view: function(vnode) {
    return m("footer", [
      m(".py-12 md:py-16", [
        m(".max-w-6xl mx-auto px-4 sm:px-6", [
          m(".grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12", [
            m("div.md:col-span-4 lg:col-span-5", [
              m(Info)
            ]),
            m("div.md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8", [
              m(BlockList, { title: "Products", items: FooterProducts.navigationBlock }),
              m(BlockList, { title: "Resources", items: FooterResources.navigationBlock }),
              m(BlockList, { title: "Project", items: FooterProject.navigationBlock })
            ]),
          ]),
          m(".md:flex md:items-center md:justify-between", [
            m(BottomLine),
            m("div.text-gray-400 text-sm mr-4", "© 2021 - ∞ konsumi | open source.")
          ])
        ])
      ])
    ])
  }
}