var m = require("mithril");
var cHome = require("../../content/home.json");

var Home = {
  getElements: function () {
    return cHome;
  },
  /**
   * load data on runtime
   */
  load: function () {
    fetch(url)
      .then(function (response) {
      })
      .catch(function (err) {
      });
  },
};

module.exports = Home;
