var m = require("mithril")

module.exports = {
  view: function(vnode) {
    return m("div.text-sm", [
      m("h6.text-gray-200 font-medium mb-1", vnode.attrs.title || ""),
      m("ul", [
        vnode.attrs.items.map(function (item) {
          if(item) {
            return m("li.mb-1", [
              m(
                "a.text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out",
                { href: item.href },
                item.title
              ),
            ]);
          }
          return "";
        })
      ])
    ])
  }
}