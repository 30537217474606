var m = require("mithril");
import { SVG } from "polythene-mithril";

module.exports = {
  view: function (vnode) {
    return m(".relative flex flex-col items-center", [
      m(
        ".absolute h-1 border-t border-dashed border-gray-700 hidden md:block",
        ""
      ),
      m(SVG, vnode.attrs.icon),
      m("h4.h4 mb-2", [m("span.text-gray-400", vnode.attrs.title)]),
      m("p.text-lg text-gray-400 text-center", vnode.attrs.description),
    ]);
  },
};
