var m = require("mithril")

import "./app.scss"

var Home = require("./views/Home");
var RethinkTheWeb = require("./views/RethinkTheWeb");
var About = require("./views/About");
var Support = require("./views/Support");
var GettingStarted = require("./views/GettingStarted");
var Roadmap = require("./views/Roadmap");
var LandingLayout = require("./layouts/Landing/Landing");
var DefaultLayout = require("./layouts/Default/Default");

m.route(document.body, "/welcome", {
    "/welcome": {
        render: function() {
            return m(LandingLayout, m(Home))
        }
    },
    "/about": {
        render: function() {
            return m(DefaultLayout, m(About))
        }
    },
    "/rethink-the-web": {
        render: function() {
            return m(DefaultLayout, m(RethinkTheWeb))
        }
    },
    "/getting-started": {
        render: function() {
            return m(DefaultLayout, m(GettingStarted))
        }
    },
    "/roadmap": {
        render: function() {
            return m(DefaultLayout, m(Roadmap))
        }
    },
    "/support": {
        render: function() {
            return m(DefaultLayout, m(Support))
        }
    }
});
