var m = require("mithril");

module.exports = {
  view: function(vnode) {
    return m("section", [
      m("div.max-w-6xl mx-auto px-4 sm:px-6", [
        m(".relative bg-purple-600 py-10 px-8 md:py-16 md:px-12", [
          m(".relative flex flex-col lg:flex-row justify-between items-center", [
            m(".mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-1/2", [
              m("h3.h3 text-white mb-2", "Stay connected"),
              m("p.text-purple-200 text-lg", "Join our newsletter to get the latest news.")
            ]),
            m("form.w-full lg:w-1/2", [
              m(".flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none", [
                m("input.w-full appearance-none bg-purple-700 border border-purple-500 focus:border-purple-300 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-purple-400", { type: "email", placeholder: "Your email..." }),
                m("a.btn text-purple-600 bg-purple-100 hover:bg-white shadow", {href: "#0"}, "Subscribe")
              ])
            ])
          ]),
        ])
      ])
    ])
  }
}