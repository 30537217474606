var m = require("mithril");
import { SVG } from "polythene-mithril";
import Icons from "../Icons/Content";
var Section = require("../Section/Section");
var SectionHeader = require("../Section/Header");
var ProcessItem = require("./Item");
var sortHelper = require("../../helper/sort");

module.exports = {
  view: function (vnode) {
    return m(
      Section,
      {
        class: "max-w-6xl mx-auto px-4 sm:px-6",
        childrenClass: "pt-10 pb-12 md:pt-16 md:pb-20",
      },
      [
        m(SectionHeader, {
          title: vnode.attrs.title,
          subtitle: vnode.attrs.subtitle
        }),
        m('div.max-w-3xl mx-auto text-center pb-12', [
          m('p.text-xl text-gray-400', vnode.attrs.description),
        ]),
        m(
          "div.max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none",
          sortHelper.sortBy(vnode.attrs.items, 'priority').map(function (item) {
            return m("div", [
              m(ProcessItem, { title: item.title, description: item.subtitle, icon: Icons[item.icon] }),
            ])
          })
        ),
      ]
    );
  },
};
