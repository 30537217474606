var m = require("mithril")

var FooterResources = {
    navigationBlock: [
        { title: 'roadmap', href: '' },
        { title: 'documentation', href: '' },
        { title: 'how to start', href: '' }
    ],
    // loadList: function() {
    //     return m.request({
    //         method: "GET",
    //         url: "https://rem-rest-api.herokuapp.com/api/users",
    //         withCredentials: true,
    //     })
    //     .then(function(result) {
    //         User.list = result.data
    //     })
    // },
}

module.exports = FooterResources