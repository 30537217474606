var m = require("mithril")
import { SVG } from "polythene-mithril"
import Icons from "./Icons";

module.exports = {
  view: function(vnode) {
    var stateClass = vnode.attrs.active ? 'block' : 'hidden';
    return m("div.w-full", {class: stateClass}, [
      m("article.relative max-w-md mx-auto md:max-w-none", [
        m("figure.md:absolute md:inset-y-0 md:right-0 md:w-1/2", [
          m("img.w-full h-full object-cover", { src: vnode.attrs.media?.src, alt: vnode.attrs.media?.alt }),
        ]),
        m(".relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl", [
          m("h4.h4 mb-2", vnode.attrs.title),
          m("p.text-lg text-gray-400", vnode.attrs.content),
          m("a.btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6", { href: "#0" }, [
            m("span.text-sm", "Learn more"),
            m(SVG, Icons.more)
          ])
        ])
      ])
    ])
  }
}