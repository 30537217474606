var m = require("mithril")
import Item from "./Item";

module.exports = {
  view: function(vnode) {
    return m("section", [
      m(".max-w-6xl mx-auto px-4 sm:px-6", [
        m(".py-12 md:py-20 border-t border-gray-800", [
          m(".max-w-3xl mx-auto text-center pb-12", [
            m("h2.h2 mb-4", vnode.attrs.title),
          ]),

          // image
          m(".max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl", [
            m("img.mx-auto md:max-w-none", { src: vnode.attrs.image })
          ]),

          m("div.max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6", [
            m("div.max-w-3xl mx-auto text-center pb-12", [
              m(".font-architects-daughter text-xl text-purple-600 mb-6 px-10", vnode.attrs.subtitle),
              m("p.text-xl text-gray-400 mb-6 px-10", vnode.attrs.description),
            ]),
            m("div.md:pl-4 lg:pl-12 xl:pl-16", [
              vnode.attrs.items.map(function(item) {
                return m(Item, { ...item });
              })
            ])
          ])
        ])
      ])
    ])
  }
}