var m = require("mithril")
var SocialIcons = require("../Icons/Social");
import { SVG } from "polythene-mithril"

module.exports = {
  view: function(vnode) {
    return m("ul.flex mb-4 md:order-1 md:ml-4 md:mb-0", [
      m("li", [
        m("a.flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out", { ariaLabel: "Twitter" }, [
          m(SVG, SocialIcons.twitter)
        ]),
      ]),
      m("li.ml-4", [
        m("a.flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out", { ariaLabel: "Twitter" }, [
          m(SVG, SocialIcons.github)
        ]),
      ]),
      m("li.ml-4", [
        m("a.flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out", { ariaLabel: "Twitter" }, [
          m(SVG, SocialIcons.facebook)
        ]),
      ]),
      m("li.ml-4", [
        m("a.flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out", { ariaLabel: "Twitter" }, [
          m(SVG, SocialIcons.instagram)
        ]),
      ]),
      m("li.ml-4", [
        m("a.flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out", { ariaLabel: "Twitter" }, [
          m(SVG, SocialIcons.linkedin)
        ])
      ])
    ])
  }
}