var m = require("mithril");
import { SVG } from "polythene-mithril";
import Icons from "./Icons";
import Button from "./Button";
import Item from "./Item";

var currentTab = 0;
var clickAction = function (id) {
  console.log("click tab item" + id);
  currentTab = id;
};

module.exports = {
  oninit: function (vnode) {
    this.currentTab = vnode.attrs.currentTab;
  },
  view: function (vnode) {
    return m("section", [
      m(".max-w-6xl mx-auto px-4 sm:px-6", [
        m(".py-12 md:py-20 border-t border-gray-800", [
          m(".max-w-3xl mx-auto text-center pb-12", [
            m("h2.h2 mb-4", vnode.attrs.title),
            m("p.text-xl text-gray-400", vnode.attrs.description),
          ]),
          m("div", [
            m(
              ".flex flex-wrap justify-center -m-2",
              vnode.attrs.tabs?.map(
                function (tab, index) {
                  var active = index == currentTab ? true : false;
                  return m(Button, {
                    active,
                    clickAction: clickAction,
                    id: index,
                    title: tab['tab-label']
                  });
                }.bind(this)
              )
            ),
            m(
              "div.relative flex flex-col mt-16",
              vnode.attrs.tabs?.map(
                function (tab, index) {
                  var active = index == currentTab ? true : false;
                  // tab contents
                  return m(Item, {
                    title: tab.title,
                    description: tab.content,
                    icon: tab.icon,
                    active,
                    id: index,
                    media: tab.media
                  });
                }.bind(this)
              )
            ),
          ]),
        ]),
      ]),
    ]);
  },
};
