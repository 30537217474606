var m = require("mithril")

var User = {
    mainNavigationItems: [
        { title: 'About', href: '/about' },
        { title: 'Rethink the Web', href: '/rethink-the-web' },
        { title: 'Getting started', href: '/getting-started' },
        { title: 'Roadmap', href: '/roadmap' },
        { title: 'Support', href: '/support' }
    ],
    // loadList: function() {
    //     return m.request({
    //         method: "GET",
    //         url: "https://rem-rest-api.herokuapp.com/api/users",
    //         withCredentials: true,
    //     })
    //     .then(function(result) {
    //         User.list = result.data
    //     })
    // },
}

module.exports = User