var m = require("mithril");

var Facts = require("../components/Process/Process");
var HomeContent = require("../models/StaticHome");
var Hero = require("../components/Hero/Hero");
var Tabs = require("../components/Tabs/Tabs");
var Target = require("../components/Target/Target");

var heroContent = HomeContent.getElements()["hero-video"];
var whatContent = HomeContent.getElements()["what-is-konsumi"];
var whyContent = HomeContent.getElements()["why-konsumi-is-revolution"];
var aimContent = HomeContent.getElements()["aims"];

import { SVG } from "polythene-mithril";
import Brand from "../components/Icons/Brand";

module.exports = {
  view: function () {
    return m("div", [
      m(Hero, {
        buttons: heroContent.actions,
        title: heroContent.title,
        description: heroContent.description,
        media: heroContent.media,
      }),
      m(".absolute right-0 top-10 hidden lg:block pointer-events-none", [
        m(SVG, Brand.leaf2)
      ]),
      m(Facts, {
        title: whatContent.title,
        subtitle: whatContent.subtitle,
        description: whatContent.description,
        items: whatContent.items
      }),
      m(Tabs, {
        title: whyContent.title,
        description: whyContent.description,
        tabs: whyContent.tabs,
        currentTab: 0
      }),
      m(Target, {
        title: aimContent.title,
        image: "",
        subtitle: aimContent.subtitle,
        items: aimContent.items,
        description: aimContent.description
      })
    ]);
  },
};