var m = require("mithril");
import { SVG } from "polythene-mithril";
import Icons from "./Icons";
var HeroImage = require("./HeroImage");
var Section = require("../Section/Section");
var Button = require("../Form/Button");
var sortHelper = require("../../helper/sort");
var Brand = require("../Icons/Brand");

module.exports = {
  view: function (vnode) {
    return m(
      Section,
      {
        class: "max-w-6xl mx-auto px-4 sm:px-6 relative",
        childrenClass: "pt-10 pb-12 md:pt-16 md:pb-20",
      },
      [
        m(".absolute left-0 bottom-0 -ml-20 lg:block pointer-events-none", [
          m(SVG, Brand.leaf1),
        ]),
        m(".relative pt-32 pb-10 md:pt-40 md:pb-16", [
          m(".max-w-3xl mx-auto text-center pb-12 md:pb-16", [
            m("h1.h1 mb-4", vnode.attrs.title),
            m("p.mb-6 text-xl", vnode.attrs.description),
            m("div.max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center", 
              sortHelper.sortBy(vnode.attrs.buttons, 'priority').map(function (item) {
                return m("div", [
                  m(Button, {
                    class: `${item.classes} w-full`,
                    label: item.title,
                    href: item.href,
                    action: item.action
                  }),
                ]);
              })),
            m(HeroImage, { media: vnode.attrs.media }),
          ]),
        ]),
      ]
    );
  },
};
