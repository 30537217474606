var m = require("mithril")
import { SVG } from "polythene-mithril"
import Icons from "./Icons";

module.exports = {
  view: function(vnode) {
    return m("section", [
      m(".relative flex justify-center items-center mt-8", [
        m("img.mx-auto", { src: 'https://via.placeholder.com/640x360'}),
        m("a.absolute group", [
          m(SVG, Icons.circle)
        ])
      ])
    ])
  }
}
