var m = require("mithril");

var Header = require("../../components/Header/Header");
var Footer = require("../../components/Footer/Footer");
var SubscribeForm = require("../../components/Footer/SubscribeForm");

module.exports = {
  view: function (vnode) {
    return m(
      "main.k-app font-inter antialiased bg-gray-900 text-gray-200 tracking-tight",
      [
        m(".flex flex-col overflow-hidden", m(Header)),
        m("main.flex-grow", [
          m("section", vnode.children),
          m(SubscribeForm),
        ]),
        m(Footer),
      ]
    );
  },
};
