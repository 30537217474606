var m = require("mithril")

module.exports = {
  view: function(vnode) {
    return m("div.mb-2", [
      m("a.inline-block", { href: "/", ariaLabel: "konsumi" }, [
        m("div.w-8 h-8 fill-current text-purple-600 font-bold m-2 text-lg",
          "KONSUMI"
        ),
        m("div.text-gray-400", "distribute anything from anyone fully controled by yourself. go and create without limits.")
      ]),
    ])
  }
}