var m = require("mithril");

var Logo = require("./Logo");
var Navigation = require("./Navigation");

module.exports = {
  view: function (vnode) {
    return m("header.absolute w-full z-30", [
      m("div.max-w-6xl mx-auto px-4 sm:px-6", [
        m("div.flex items-center justify-between h-20", [m(Logo), m(Navigation)]),
      ]),
    ]);
  },
};
