var m = require("mithril");
import { SVG } from "polythene-mithril";
import Icons from "./Icons";

module.exports = {
  view: function (vnode) {
    var stateClass = vnode.attrs.active ? "" : "opacity-50";
    return m(
      "button.flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500",
        { onclick: function() { vnode.attrs.clickAction(vnode.attrs.id) }, class: stateClass },
      [
        m(SVG, Icons.button),
        m(
          "span.text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out",
          vnode.attrs.title
        ),
      ]
    );
  },
};
