var m = require("mithril");

module.exports = {
  view: function (vnode) {
    return m(".max-w-3xl mx-auto text-center pb-12 md:pb-20", { class: vnode.attrs.class }, [
      m("h2.h2 mb-4", { class: vnode.attrs.headlineClass }, vnode.attrs.title),
      m(
        "p.text-xl text-gray-400",
        vnode.attrs.subtitle
      ),
      vnode.children
    ]);
  },
};
